/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { AccountCheck } from './BasicHeaderComponents/AccountCheck'
import { AddSocialButton } from './BasicHeaderComponents/AddSocialButton'

/* ------------------- INTERFACES  */
interface Props {}

export const BasicHeader = ({}: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost, socialsAdded } = useCreatePostStore((s) => ({
    activePost: s.activePost as any,
    socialsAdded: s.socialsAdded,
  }))

  return (
    <BasicHeaderContainer>
      {socialsAdded.length === 0 && <AddSocialButton />}

      {socialsAdded.length > 0 && (
        <BasicHeaderContent>
          {/* <GlobalCheck isGlobalActive={activePost?._id === 'GLOBAL'} /> */}
          <ScrollAccountsContainer>
            {/* Checkbox Accounts */}
            {socialsAdded.map((account) => (
              <AccountCheck account={account} />
            ))}
          </ScrollAccountsContainer>
        </BasicHeaderContent>
      )}
    </BasicHeaderContainer>
  )
}

const BasicHeaderContainer = styled(Box)`
  align-self: flex-start;
`

const BasicHeaderContent = styled(Box)`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

const ScrollAccountsContainer = styled(Box)`
  max-width: 700px;
  width: 700px;
  flex-direction: row;
  flex: 1;
  gap: 16px;
  padding-top: 4px;

  ::-webkit-scrollbar {
    display: none;
  }
`
