import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { AppStore } from 'utils'
import { Option, SelectedOpt } from './GeneralComponentsTypes'
import { useNavigate, useLocation } from 'react-router-dom'

interface Props {
  element: Option[]
  elIndex: number
  isActive: (optIndex: number, childIndex: number) => string
  selected: SelectedOpt[]
  setSelected: (newSelected) => void
  whatPage: string
  innerSelected?: SelectedOpt[]
  innerIndex?: number
  setInnerSelected?: (newSelected) => void
  innerE?: SelectedOpt
  setIsConditionalIsClicked?: () => void
}

const ConditionalOption = ({
  element,
  elIndex,
  isActive,
  selected,
  whatPage,
  setSelected,
  innerSelected,
  setInnerSelected,
  innerIndex,
  innerE,
  setIsConditionalIsClicked,
}: Props) => {
  /* --------------------------------------------- ROUTER */
  const navigate = useNavigate()

  /* --------------------------------------------- VARIABLES */
  const conditionalOptions = element

  let anyVisible = false

  for (const option of conditionalOptions) {
    if (option?.visible?.(selected[elIndex].selectedIndex)) {
      anyVisible = true
      break
    }
  }

  /* --------------------------------------------- METHODS */
  const onClickOption = (opt: Option) => {
    let control = ''
    // if function is defined
    if (opt.onSelect.function) control = opt.onSelect.function()

    // if notice is defined
    opt.onSelect.notice && opt.onSelect.notice()

    if (control === 'error') return
    // if navigate is defined

    if (opt.onSelect.navigate) {
      const optState: any = opt.onSelect.state
      if (whatPage === 'analytics' && optState.stateConditionalClicked) {
        setIsConditionalIsClicked && setIsConditionalIsClicked()
      }
      navigate(opt.onSelect.navigate, { state: opt.onSelect.state })
    }
  }

  if (!anyVisible) return null
  return (
    <Options style={{ position: 'absolute', bottom: -100 }}>
      {conditionalOptions.map((option, index) => {
        if (option?.visible?.(selected[elIndex].selectedIndex)) {
          return (
            <InnerContainer
              key={`inner_option_${option.id}`}
              className={isActive(elIndex, index)}
              onClick={() => {
                if (option.disabled) return
                onClickOption(option)
              }}
            >
              {option.icon}
              <Text>{option.title}</Text>
            </InnerContainer>
          )
        }
      })}
    </Options>
  )
}

export default ConditionalOption

const Options = styled(Box)`
  position: absolute;
  height: auto;
  background-color: ${() => AppStore.theme.o.white};
  display: flex;

  left: 4px;
  width: 94px;
  height: 80px;
  gap: 16px;
  flex-direction: column;
  box-shadow: 5.92154px 6.77756px 13px rgba(38, 42, 72, 0.08), 2.28089px 2.61062px 4.14074px rgba(38, 42, 72, 0.04),
    0.482496px 0.552246px 1.05926px rgba(38, 42, 72, 0.02);
  transform: translateX(-10px);
`

const InnerContainer = styled(Box)`
  width: 94px;
  height: 80px;
  background-color: ${() => AppStore.theme.o.blue};
  /* center */
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 36px;
    height: 36px;
    path {
      fill: ${() => AppStore.theme.o.white};
    }
  }

  :hover {
    background-color: ${() => AppStore.theme.o.lightBlue};
    svg {
      path {
        fill: ${() => `${AppStore.theme.o.blue}`};
      }
    }
    text {
      color: ${() => `${AppStore.theme.o.blue}`};
    }
  }

  &.active {
    background-color: ${() => AppStore.theme.o.lightBlue};
    border-left: 1px solid ${() => AppStore.theme.o.blue};

    svg {
      path {
        fill: ${() => `${AppStore.theme.o.blue}`};
      }
    }
    text {
      color: ${() => `${AppStore.theme.o.blue}`};
    }
  }
  &.special  {
    width: 9;
  }
`

const Text = styled.text`
  font-size: 12px;
  font-style: normal;
  color: ${() => AppStore.theme.o.white};
  font-weight: 400;
`
