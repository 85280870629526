import { Box, Icons, Text } from 'components'
import PostPageAnimation from 'components/Animations/PostPageAnimation'
import { openMediaModal } from 'components/Modal/ModalManager'

import { useNavigate } from 'react-router-dom'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { Media } from 'types'
import { AppStore, T } from 'utils'
import { getSelectedMedias } from 'utils/PostUtils/MediaPost'
import { updateActive } from 'utils/PostUtils/Post'

export const UploadMedia = () => {
  const navigate = useNavigate()
  const {socialsAdded} = useCreatePostStore.getState()

  return (
    <Box
      onClick={async () => {
        if (socialsAdded?.length === 0) return;
        
        navigate(`/post/media`)
        openMediaModal({ onMediaSelected: (media: Media[]) => updateActive({ medias: media }), selectedMedias: [] })
      }}
      style={{
        objectFit: 'cover',
        width: 'auto',
        height: '100%',
        minWidth: '-webkit-fill-available',
        borderRadius: '14px',
      }}
      center
      onHoverStart={() => {
        socialsAdded?.length > 0 && PostPageAnimation.onHoverStartPreview()
      }}
      onHoverEnd={() => {
        socialsAdded?.length > 0 && PostPageAnimation.onHoverEndPreview()
      }}
    >
      <Box id={'iconContainer'} style={{ padding: 0 }}>
        <Icons.uploadFile id="iconUpload" style={{ width: 48, height: 48 }} fill={AppStore.theme.o.darkGrey} />
      </Box>
      <Box style={{ opacity: 0 }} id="textBox">
        <Text style={{ fontSize: 24, fontWeight: 500, color: AppStore.theme.o.darkGrey }}>
          {T.DashboardPage.upload_media}
        </Text>
      </Box>
    </Box>
  )
}
